<template>
  <main>
    <div class="container">
      <div class="col-sm-8 mx-auto py-5">
        <div class="message-box text- p-4 rounded-lg shadow-mdn py-5" v-if="sent">
          <div class="row">
            <div class="col-sm-12">
              <div class="text-center mb-4">
                <h4 class="text-">Lien de réinitialisation de compte envoyé.</h4>
                <h6 class="text-muted mb-4">Un lien vous a été envoyé par courriel pour la réinitialisation de votre compte.</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-white p-4 rounded-lg shadow-md py-5" v-else>
          <div class="row" v-if="tokenInfo != null && tokenInfo.tokenType != 'Agent'">
            <div class="col-sm-12">
              <div class="text-center mb-4">
                <h4 class="text-primary">Votre adresse a été vérifié avec succès!</h4>
                <h6 class="text-muted mb-4">Vous pouvez maintenant vous connecter à votre compte</h6>
                <router-link class="btn btn-primary" :to="{ name: 'accounts.login.de' }">Accéder à mon compte </router-link>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
                <h4 class="text-primary text-">Vous pourriez réinitialiser votre mot de passe à partir de votre messagerie.</h4>
                <h6 class="text-muted mb-4 text-">Veuillez renseigner votre email.</h6>
              <div class="text- mb-4">
                <div v-if="error" class="alert alert-danger mb-4 text-center">
                  <span class="text-danger">{{ error.message }}</span>
                </div>
                <ValidationObserver ref="observer"  v-slot="{ handleSubmit }">
                  <form ref="form" method="post" @submit.prevent="handleSubmit(onSubmit)" accept-charset="utf-8">
                    <ValidationProvider rules="required|email" v-slot="{ errors }">
                      <div class="form-floating mb-3">
                        <input v-model="user.username" type="text" class="form-control" name="email" id="email" placeholder="name@example.com">
                        <label for="email">Adresse Email</label>
                        <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                      </div>
                    </ValidationProvider>
                    <div class="d-grid gap-2 my-4">
                      <button type="submit" class="btn btn-primary btn-lg">Valider</button>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions } from 'vuex'
import { authMixin } from '../../mixins/auth-mixin'
export default {
  mixins: [authMixin],
  data(){
    return{
      tokenInfo: null,
      error: null,
      sent: false,
      user: {
        username: null,
        password: null,
        password1: null
      },
    }
  },
  created () {
    this.forceLogout()
  },
  methods: {
    ...mapActions({
      resetPassword: 'account/resetPassword',
    }),
    async onSubmit () {
      this.loading = true
      this.resetPassword({
        ...this.user
      }).then(() => {
        // this.$router.push({ name: 'espace.de.home' })
        this.sent = true
      }).catch(err => this.error = err.response.data)
    }
  },
}
</script>

<style>
  .message-box{
    background: #ffc10745!important;
  }
</style>